
import React, { useState } from 'react';
import './Profile.css';

export default function Profile() {
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const [profilePicture, setProfilePicture] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [showPasswordSection, setShowPasswordSection] = useState(false);

  // Handle profile picture change
  const handlePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(URL.createObjectURL(e.target.files[0]));
    }
  };

  // Handle input changes for user details
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // Handle form submission (Save button)
  const handleSubmit = (e) => {
    e.preventDefault();

    // Phone number validation
    const phonePattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    if (!phonePattern.test(user.phone)) {
      alert('Please enter a valid phone number in the format 123-456-7890.');
      return;
    }

    // Password validation
    if (password && password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    // Update user information logic goes here (e.g., API call)
    alert('Profile updated successfully!');
  };

  // Handle account deletion
  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      // Delete account logic goes here (e.g., API call)
      setUser(null);
      alert('Your account has been deleted.');
      window.location.href = '/';
    }
  };

  return (
    <div className="profile-container">
      <h1>My Profile</h1>
      <form onSubmit={handleSubmit}>
        <div className="profile-picture-section">
          <img
            src={profilePicture || 'https://via.placeholder.com/150'}
            alt="Profile"
            className="profile-picture"
          />
          <input type="file" accept="image/*" onChange={handlePictureChange} />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={user.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={user.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="123-456-7890"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            title="Phone number must be in the format 123-456-7890"
            value={user.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <textarea
            id="address"
            name="address"
            placeholder="Enter your address"
            value={user.address}
            onChange={handleChange}
            rows="3"
          ></textarea>
        </div>

        {/* Password Section Toggle */}
        <div className="form-group">
          <button
            type="button"
            className="change-password-btn"
            onClick={() => setShowPasswordSection(!showPasswordSection)}
          >
            {showPasswordSection ? 'Hide Password Section' : 'Change Password'}
          </button>
        </div>

        {/* Collapsible Password Section */}
        {showPasswordSection && (
          <div className="password-section">
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password:</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">New Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password:</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
              />
            </div>
          </div>
        )}

{/* Button Group */}
<div className="button-group">
  <button
    type="button"
    className="btn btn-danger"
    onClick={handleDeleteAccount}
  >
    Delete Account
  </button>
  <button type="submit" className="primary">
    Save
  </button>
</div>

      </form>
    </div>
  );
}







/////// best ///////
