import React from 'react';
import './SignUp.css';
import Image from 'react-bootstrap/Image';

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstNameData: '',
      emailData: '',
      passwordData: '',
      confirmPasswordData: '',
      submitted: false,
      allValid: false,
      emailValid: true,
      emailExists: false, // State to track if email already exists
      errorMessage: '' // State to hold error message from the server
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.firstNameValidation = this.firstNameValidation.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.passwordValidation = this.passwordValidation.bind(this);
    this.confirmPasswordValidation = this.confirmPasswordValidation.bind(this);
  }

  submitHandler(event) {
    event.preventDefault();



    this.setState({
      submitted: true,
    });

    if (
      this.state.firstNameData.trim() !== '' &&
      this.state.emailValid &&
      this.state.passwordData.trim() !== '' &&
      this.state.confirmPasswordData.trim() !== '' &&
      this.state.passwordData === this.state.confirmPasswordData
    ) {
      this.setState({
        allValid: true,
      });

      setTimeout(() => {
        this.setState({
          allValid: false,
        });
      }, 5000);
    }

    // Check if email already exists
    fetch('http://138.197.161.82:3000/signup', {
      method: 'POST',
      mode: 'cors',
      headers: {
        "ACCEPT": "*/*",
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.state.firstNameData,
        email: this.state.emailData,
        password: this.state.passwordData
      })
    })
      .then(response => {
        console.log('response: ' + JSON.stringify(response)); // testing
        console.log('response type: ' + typeof(response)); // testing
        console.log(response.status);
        return JSON.stringify(response);
      })
      .then(data => {
        console.log("Received data:", data); // Log the received data
        if (data.message === 'User created') {
          console.log('User registered successfully');
          // Redirect to login page or show success message
        } else {
          console.error(data.message);
          if (data.message === 'Email already exists') {
            this.setState({ emailExists: true });
          }
        }
      })
      .catch(error => {
        console.error('Fetch error:', error); // Handle fetch error
        this.setState({ errorMessage: 'Failed to register. Please try again later.' });
      });
  }

  firstNameValidation(event) {
    this.setState({
      firstNameData: event.target.value,
    });
  }

  emailValidation(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      emailData: email,
      emailValid: emailRegex.test(email),
    });
  }

  passwordValidation(event) {
    this.setState({
      passwordData: event.target.value,
    });
  }

  confirmPasswordValidation(event) {
    this.setState({
      confirmPasswordData: event.target.value,
    });
  }

  render() {
    return (
      <div>
        <div className="signup-container">
          <div className="form-container">
            <form
              className="register-form"
              onSubmit={this.submitHandler}
              autoComplete="off"
            >
              {this.state.submitted && this.state.allValid && (
                <div className="success-message">
                  Success! Thank you for registering
                </div>
              )}
              {this.state.errorMessage && (
                <div className="error-message">{this.state.errorMessage}</div>
              )}
              {this.state.emailExists && (
                <div className="error-message">Email already exists</div>
              )}
              <input
                id="first-name"
                onChange={this.firstNameValidation}
                value={this.state.firstNameData}
                className="form-field"
                type="text"
                placeholder="Name"
                name="firstName"
              />
              {this.state.submitted && this.state.firstNameData.trim() === '' && (
                <span id="first-name-error">Please enter a first name</span>
              )}
              <input
                id="email"
                onChange={(e) => this.emailValidation(e.target.value)}
                value={this.state.emailData}
                className="form-field"
                type="text"
                placeholder="Email"
                name="email"
              />
              {this.state.submitted && this.state.emailData.trim() === '' && (
                <span id="email-error">Please enter an email address</span>
              )}
              {!this.state.emailValid && (
                <span id="email-error">Please enter a valid email address</span>
              )}
              <input
                id="password"
                onChange={this.passwordValidation}
                value={this.state.passwordData}
                className="form-field"
                type="password"
                placeholder="Password"
                name="password"
              />
              {this.state.submitted && this.state.passwordData.trim() === '' && (
                <span id="password-error">Please enter your password</span>
              )}
              <input
                id="confirm-password"
                onChange={this.confirmPasswordValidation}
                value={this.state.confirmPasswordData}
                className="form-field"
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
              />
              {this.state.submitted && this.state.confirmPasswordData !== this.state.passwordData && (
                <span id="confirm-password-error">
                  The passwords do not match
                </span>
              )}
              <button className="form-field" type="submit">
                Register
              </button>
            </form>
          </div>
          <div className="image-container">
            <Image className="image" src="image.png" thumbnail roundedCircle style={{ border: 'none' }} />
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;

