import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./Preview.css";
import { FaSearchPlus } from "react-icons/fa"; // Import magnifier icon from react-icons library
import { productsData } from "../utils/data/product";

function PreviewPage() {
  const { id } = useParams();

  // Find the product with matching ID
  const selectedProduct = productsData.find((product) => product.id === id);

  // Extract title from selected product
  const title = selectedProduct ? selectedProduct.name : "";

  // State to track magnifier visibility
  const [magnifierImage, setMagnifierImage] = useState(null);

  // Function to toggle magnifier visibility and set magnifier image
  const toggleMagnifier = (image) => {
    setMagnifierImage(image);
  };

  return (
    <div className="preview-page">
      <div className="preview-container">
        <div className="preview-card-container">
          <div className="preview-card">
            <img
              src={`${process.env.PUBLIC_URL}/${selectedProduct.blouse.image}`}
              alt={selectedProduct.name}
              onClick={() => toggleMagnifier(selectedProduct.blouse.image)}
            />
            <div className="magnifier-icon">
              <FaSearchPlus
                onClick={() => toggleMagnifier(selectedProduct.blouse.image)}
              />
            </div>
          </div>
          <div className="preview-card">
            <img
              src={`${process.env.PUBLIC_URL}/${selectedProduct.pants.image}`}
              alt={selectedProduct.name}
              onClick={() => toggleMagnifier(selectedProduct.pants.image)}
            />
            <div className="magnifier-icon">
              <FaSearchPlus
                onClick={() => toggleMagnifier(selectedProduct.pants.image)}
              />
            </div>
          </div>
          <div className="preview-card">
            <img
              src={`${process.env.PUBLIC_URL}/${selectedProduct.shoe.image}`}
              alt={selectedProduct.name}
              onClick={() => toggleMagnifier(selectedProduct.shoe.image)}
            />
            <div className="magnifier-icon">
              <FaSearchPlus
                onClick={() => toggleMagnifier(selectedProduct.shoe.image)}
              />
            </div>
          </div>
        </div>
        <div className="description-container">
          <div>
            <h2 className="preview-title">{title}</h2>
            {selectedProduct && (
              <div className="description">
                <div>
                  <h4>{selectedProduct.blouse.name}</h4>
                  <p>{selectedProduct.blouse.description}</p>
                </div>
                <div>
                  <h4>{selectedProduct.pants.name}</h4>
                  <p>{selectedProduct.pants.description}</p>
                </div>
                <div>
                  <h4>{selectedProduct.shoe.name}</h4>
                  <p>{selectedProduct.shoe.description}</p>
                </div>
              </div>
            )}
            {/* Back to Product page button */}
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Link to="/product" className="mix-and-match-btn">
              Back to Product
            </Link>
            {/* Mix and Match button */}
            <Link
              to={`/mix-and-match/${id}`}
              className="mix-and-match-btn"
              style={{ marginLeft: "10px" }}
            >
              Mix and Match
            </Link>
          </div>
        </div>
      </div>
      {/* Magnifier */}
      {magnifierImage && (
        <div
          className="magnifier-overlay"
          onClick={() => setMagnifierImage(null)}
        >
          <div className="magnifier-content">
            <img
              src={`${process.env.PUBLIC_URL}/${magnifierImage}`}
              alt={selectedProduct.name}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PreviewPage;
